import React from 'react';
import Seo from '../components/info-site/Seo';
import Layout from '../layouts/en';


const GtmDocs = (props) => {
	return (
		<Layout location={props.location}>
			<Seo titleId="gtmDocsTitle" descriptionId="gtmDocsDescription" />
      <h1>To be edited</h1>
      {/* <CaseStudies
        title="gtmDocsTitle"
        subtitle="gtmDocsDescription"
      /> */}
      <h1></h1>
		</Layout>
	);
};

export default GtmDocs;
